import { getTelemetryObject, ITelemetryContent } from '@msdyn365-commerce-modules/utilities';
import { ProductDimensionFull } from '@msdyn365-commerce/commerce-entities';
import * as React from 'react';
import { IBuyboxCallbacks, IBuyboxCommonData, IBuyboxCommonResources, IBuyboxExtentedProps, IBuyboxProductConfigureDropdownViewProps, IBuyboxProductConfigureViewProps, IBuyboxState, IProductDetails } from './buyboxInterface';
import { Dropdown, IDropdownItemProps, IDropdownOnSelectionChangeNotification } from './dropdown';

let telemetryContent: ITelemetryContent;

export function getBuyboxProductConfigure(props: IBuyboxExtentedProps<IBuyboxCommonData>, state: IBuyboxState, callbacks: IBuyboxCallbacks, productDetails?: IProductDetails): IBuyboxProductConfigureViewProps | undefined {
    const {
        resources,
        typeName
    } = props;

    const
        {
            errorState: {
                configureErrors,
            }
        } = state;

    const
        {
            getDropdownName
        } = callbacks;

    const product = props.data.product?.result ? props.data.product?.result : productDetails?.product!;
    const productDimensions = props.data.productDimensions?.result ? props.data.productDimensions?.result : productDetails?.productDimensions!;

    if (!product || !productDimensions) {
        return undefined;
    }

    const className = _getClassNamePrefix(typeName);

    const onChanged = async (notification: IDropdownOnSelectionChangeNotification) =>
        _onChanged(notification, callbacks.dimensionSelectedAsync);

    telemetryContent = getTelemetryObject(props.context.request.telemetryPageName!, props.typeName, props.telemetry);
    const dropdowns = productDimensions.map((productDimensionFull: ProductDimensionFull) => _mapProductDimensionFullToDropdownViewProps(productDimensionFull, resources, configureErrors, getDropdownName, onChanged, className));

    if (!dropdowns || dropdowns.length === 0) {
        return undefined;
    }

    return {
        ContainerProps: {
            className: `${className}__configure`
        },
        dropdowns: dropdowns
    };
}

const _onChanged = async (
    notification: IDropdownOnSelectionChangeNotification,
    dimensionChanged: (newValue: number, selectedDimensionValue: string) => Promise<void>,
): Promise<void> => {
    await dimensionChanged(+notification.dropdownId, notification.selectId);
};

const _mapProductDimensionFullToDropdownViewProps =
    (
        productDimensionFull: ProductDimensionFull,
        resources: IBuyboxCommonResources,
        configureErrors: { [configureId: string]: string | undefined },
        getDropdownName: (dimensionType: number, resources: IBuyboxCommonResources) => string,
        dropdownCallback: (notification: IDropdownOnSelectionChangeNotification) => Promise<void>,
        className: string
    ): IBuyboxProductConfigureDropdownViewProps => {
        const dropdownName = getDropdownName(productDimensionFull.DimensionTypeValue, resources);
        const dropdownId = productDimensionFull.DimensionTypeValue.toString();
        const dropdownToggleName = resources.selectDimensionFormatString.replace('{0}', dropdownName.toLocaleLowerCase());
        const dropdownList: IDropdownItemProps[] = productDimensionFull.DimensionValues
            ? productDimensionFull.DimensionValues.map<IDropdownItemProps>(dimensionValue => {
                return {
                    value: dimensionValue.Value || '',
                    id: dimensionValue.RecordId.toString()
                };
            })
            : [];
        const errorMessage = configureErrors[dropdownId];

        return {
            ContainerProps: {
                className: `${className}__dropdown`
            },
            LabelContainerProps: {
                tag: 'label',
                className: `${className}__dropdown-quantity-label`,
                htmlFor: `${className}__dropown-quantity-input-${dropdownId}`
            },
            errors: errorMessage && (
                <span className='msc-alert msc-alert-noborder msc-alert-danger'>
                    <span className='msi-exclamation-triangle' aria-hidden='true' />
                    <span>{errorMessage}</span>
                </span>
            ),
            heading: (
                <div>{dropdownName}</div>
            ),
            select: (
                <Dropdown
                    dropdownId={dropdownId}
                    dropdownName={dropdownName}
                    dropdownToggleName={dropdownToggleName}
                    dropdownList={dropdownList}
                    onChange={dropdownCallback}
                    telemetryContent={telemetryContent}
                />
            )
        };
    };

const _getClassNamePrefix = (typeName: string): string => {
        return typeName.toLocaleLowerCase() === 'quickview' ? 'ms-quickView' : 'ms-buybox';
    };
